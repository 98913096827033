
export default {
    graphicReport : {
        series: [{
            data: [21, 22, 10, 28, 16, 21, 13, 30]
        }],
        chartOptions: {
            chart: {
                height: 350,
                type: 'bar',
                events: {
                    click: function(chart, w, e) {
                        // console.log(chart, w, e)
                    }
                }
            },
            colors: ['#3498db', '#2ecc71', '#f1c40f', '#e74c3c', '#9b59b6', '#34495e', '#1abc9c', '#e67e22'],
            plotOptions: {
                bar: {
                columnWidth: '45%',
                distributed: true,
                }
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: false
            },
            xaxis: {
                categories: [
                    'Indosat',
                    'HCPT',
                    'Smartfren',
                    'Telkomsel',
                    'XL',
                    'Axis',
                    'Berca',
                    'KAI'
                ],
                labels: {
                    style: {
                        colors: ['#3498db', '#2ecc71', '#f1c40f', '#e74c3c', '#9b59b6', '#34495e', '#1abc9c', '#e67e22'],
                        fontSize: '12px'
                    }
                }
            }
        }
    },
    summaryProduct : {
        series: [44, 55, 13, 43, 22],
        chartOptions: {
            chart: {
                width: 380,
                type: 'pie',
            },
            labels: ['Product A', 'Product B', 'Product C', 'Product D', 'Product E'],
            responsive: [{
                breakpoint: 480,
                options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
                }
            }]
        },
    },
    summaryCompany : {
        series: [44, 55, 13, 43, 22],
        chartOptions: {
            chart: {
                width: 380,
                type: 'pie',
            },
            labels: ['Company A', 'Company B', 'Company C', 'Company D', 'Company E'],
            responsive: [{
                breakpoint: 480,
                options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
                }
            }]
        },
    }
}