<template>

  <div id="page-user-list">
    <vx-card class="mb-base" v-if="deadlineTicket.length > 0">
        <div class="flex deadline-ticket">
            <template v-for="(data, index) in deadlineTicket">
                <div class="item" :key="data.complain_id">
                    <h3 class="text-danger">{{ data.company.company_name }}</h3>
                    <h5>{{ data.priority }}</h5>
                    <div class="divider my-2"></div>
                    <b>Deadline</b>
                    <p>Date : {{ data.deadline.substring(0, 10) }}</p>
                    <p>Time : {{ data.waktu }}</p>
                </div>
                <div class="divider-vert mx-6" v-if="index != (deadlineTicket.length - 1)" :key="data.complain_id"></div>
            </template>
        </div>
    </vx-card>        

    <vx-card class="mb-base">
        <vs-tabs>
            <vs-tab label="Graphic Report">
                <div class="tab-text">
                    <div class="flex justify-end">
                        <div class="flex justify-end md:w-4/12">
                            <v-select class="mr-2 md:w-6/12" :options="months" :clearable="false" v-model="month"></v-select>
                            <v-select class="md:w-4/12" v-on:change="getGraphicReport" :options="years" :clearable="false" v-model="year"></v-select>
                        </div>
                    </div>
                    <div class="my-4 flex align-center justify-center">
                        <h4>Report Ticket Monthly {{month.label}} - {{year.label}}</h4>
                    </div>
                    <vue-apex-charts type="bar" height="350" :options="graphicReport.options" :series="graphicReport.series"></vue-apex-charts>
                </div>
            </vs-tab>
            <vs-tab label="Status Warranty">
                <div class="tab-text">
                    <div class="my-4 flex justify-between">
                        <h5 class="mt-4">Status Warranty</h5>
                        <vs-button class="small" color="primary">Add New</vs-button>
                    </div>
                    <vs-table :data="statusWarranty">

                        <template slot="thead">
                            <vs-th>Customer</vs-th>
                            <vs-th>Level</vs-th>
                            <vs-th>Status</vs-th>
                            <vs-th>PO Ref.</vs-th>
                            <vs-th>DO Ref.</vs-th>
                            <vs-th>Periode</vs-th>
                            <vs-th>Sales</vs-th>
                        </template>

                        <template slot-scope="{data}">
                            <vs-tr :key="indextr" v-for="(tr, indextr) in data">

                                <vs-td :data="data[indextr].customer">
                                {{ data[indextr].customer }}
                                </vs-td>

                                <vs-td :data="data[indextr].level">
                                {{ data[indextr].level }}
                                </vs-td>

                                <vs-td :data="data[indextr].status">
                                {{ data[indextr].status }}
                                </vs-td>

                                <vs-td :data="data[indextr].po_reference">
                                {{ data[indextr].po_reference }}
                                </vs-td>

                                <vs-td :data="data[indextr].do_reference">
                                {{ data[indextr].do_reference }}
                                </vs-td>

                                <vs-td :data="data[indextr].periode">
                                {{ data[indextr].periode }}
                                </vs-td>

                                <vs-td :data="data[indextr].sales">
                                {{ data[indextr].sales }}
                                </vs-td>

                            </vs-tr>
                        </template>

                    </vs-table>
                </div>
            </vs-tab>
        </vs-tabs>
    </vx-card>

    <div class="vx-row">
        <div class="vx-col w-full lg:w-7/12">
            <vx-card>
                <vs-tabs>
                    <vs-tab label="Trouble Ticket by Priority">
                        <div class="tab-text">
                            <div class="vx-row mt-base">

                                <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">
                                    <statistics-card-line
                                    hideChart
                                    class="mb-base"
                                    icon="InfoIcon"
                                    color="danger"
                                    icon-right
                                    :statistic="ticketPriority.critical"
                                    statisticTitle="Critical" />
                                </div>

                                <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">
                                    <statistics-card-line
                                    hideChart
                                    class="mb-base"
                                    icon="InfoIcon"
                                    color="warning"
                                    icon-right
                                    :statistic="ticketPriority.emergency"
                                    statisticTitle="Emergency" />
                                </div>

                                <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">
                                    <statistics-card-line
                                    hideChart
                                    class="mb-base"
                                    icon="InfoIcon"
                                    color="danger"
                                    icon-right
                                    :statistic="ticketPriority.urgent"
                                    statisticTitle="Urgent" />
                                </div>

                                <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">
                                    <statistics-card-line
                                    hideChart
                                    class="mb-base"
                                    icon="InfoIcon"
                                    color="warning"
                                    icon-right
                                    :statistic="ticketPriority.major"
                                    statisticTitle="Major"/>
                                </div>
                            </div>
                            <div class="vx-row">

                                <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">
                                    <statistics-card-line
                                    hideChart
                                    icon="InfoIcon"
                                    icon-right
                                    :statistic="ticketPriority.minor"
                                    statisticTitle="Minor" />
                                </div>

                                <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">
                                    <statistics-card-line
                                    hideChart
                                    icon="InfoIcon"
                                    icon-right
                                    :statistic="ticketPriority.question"
                                    statisticTitle="Question"
                                    color="dark" />
                                </div>
                            </div>
                        </div>
                    </vs-tab>
                    <vs-tab label="Trouble Ticket by Status">
                        <div class="tab-text">
                            <div class="vx-row mt-base">

                                <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">
                                    <statistics-card-line
                                    hideChart
                                    class="mb-base"
                                    icon="InfoIcon"
                                    color="danger"
                                    icon-right
                                    :statistic="ticketStatus.opened"
                                    statisticTitle="Opened" />
                                </div>

                                <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">
                                    <statistics-card-line
                                    hideChart
                                    class="mb-base"
                                    icon="UnlockIcon"
                                    color="primary"
                                    icon-right
                                    :statistic="ticketStatus.inProcess"
                                    statisticTitle="In Process"/>
                                </div>
                            </div>
                            <div class="vx-row">

                                <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">
                                    <statistics-card-line
                                    hideChart
                                    class="mb-base"
                                    color="primary"
                                    icon="UnlockIcon"
                                    icon-right
                                    :statistic="ticketStatus.inProcessPrincipal"
                                    statisticTitle="In Process Principal" />
                                </div>

                                <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">
                                    <statistics-card-line
                                    hideChart
                                    class="mb-base"
                                    icon="UnlockIcon"
                                    icon-right
                                    :statistic="ticketStatus.inProcessCustomer"
                                    statisticTitle="In Process Customer"
                                    color="primary" />
                                </div>
                            </div>
                            <div class="vx-row">

                                <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">
                                    <statistics-card-line
                                    hideChart
                                    icon="LockIcon"
                                    icon-right
                                    :statistic="ticketStatus.closed"
                                    statisticTitle="Closed"
                                    color="dark" />
                                </div>

                                <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">

                                </div>
                            </div>
                        </div>
                    </vs-tab>
                </vs-tabs>
            </vx-card>
        </div>
        <div class="vx-col w-full lg:w-5/12">
            <vx-card>
                <!-- <div class="vx-row"> -->
                    <vs-button @click="summary = 1" :type="(summary == 1) ? 'filled' : 'border'" class="small mr-2">Summary by Product</vs-button>
                    <vs-button @click="summary = 2" class="small outlined" :type="(summary == 2) ? 'filled' : 'border'" color="primary">Summary by Company</vs-button>
                <!-- </div> -->
                <div class="mt-8 mb-4" v-if="summary == 1">
                    <vue-apex-charts type="pie" height="360" :options="graphicTicketProduct.options" :series="graphicTicketProduct.series"></vue-apex-charts>
                </div>
                <div class="mt-8 mb-4" v-else>
                    <vue-apex-charts type="pie" height="360" :options="graphicTicketCompany.options" :series="graphicTicketCompany.series"></vue-apex-charts>
                </div>
            </vx-card>
        </div>
    </div>
  </div>
</template>

<style scoped>
    .deadline-ticket {
        overflow-x: hidden;
    }

    .divider {
        border-bottom: 2px dashed #DDDDDD;
        border-width: thin;
    }

    .divider-vert {
        border-right: 1px solid #EDEDED;
        border-width: thin;
    }
</style>

<script>
import VueApexCharts from 'vue-apexcharts'
import chartData from './chartData.js'
import tableData from './tableData.js'

import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'

export default {
    components: {
        VueApexCharts,
        StatisticsCardLine
    },
    data() {
        return {
            loaded: false,
            chartData,
            tableData,
            deadlineTicket: [],
            statusWarranty: [],
            graphicReport: {
                series: [],
                options: {}
            },
            graphicTicketProduct: {
                series: [],
                options: {}
            },
            graphicTicketCompany: {
                series: [],
                options: {}
            },
            ticketPriority: {
                urgent: 0,
                major: 0,
                minor: 0,
                question: 0,
                critical: 0,
                emergency: 0
            },
            ticketStatus: {
                opened: 0,
                inProcess: 0,
                inProcessPrincipal: 0,
                inProcessCustomer: 0,
                closed: 0
            },
            summary: 1,
            test: 'Tulisan Test',

            month: {
                label: '',
                value: ''
            },
            months: [
                { label: 'Januari', value: '01' },
                { label: 'Februari', value: '02' },
                { label: 'Maret', value: '03' },
                { label: 'April', value: '04' },
                { label: 'Mei', value: '05' },
                { label: 'Juni', value: '06' },
                { label: 'Juli', value: '07' },
                { label: 'Agustus', value: '08' },
                { label: 'September', value: '09' },
                { label: 'Oktober', value: '10' },
                { label: 'November', value: '11' },
                { label: 'Desember', value: '12' }
            ],
            year: {
                label: '',
                value: ''
            },
            years: [],
        }
    },
    methods : {
        getDeadlineTicket () {
            const self = this
            this.$store.dispatch('dashboard/fetchDeadlineTicket')
            .then(res => {
                const data = res.data.data
                self.deadlineTicket = data
            })
        },
        getCurrentTime () {
            var months    = ['Januari','Februari','Maret','April','Mei','Juni','Juli','Agustus','September','Oktober','November','Desember']
            var now       = new Date()
            var thisMonth = months[now.getMonth()]
            var thisYear  = now.getFullYear()
            var min       = 2014;
            var max       = thisYear;

            for (var i = min; i <= max; i++) {
                this.years.push({
                    label : i,
                    value : i
                })
            }

            this.month = {
                label : thisMonth,
                value : now.getMonth() + 1
            }

            this.year = {
                label : thisYear,
                value : thisYear
            }

            
        },
        getGraphicReport () {
            const self = this
            this.$store.dispatch('dashboard/fetchReportTicket', { 
                month: self.month.value,
                year: self.year.value
            })
            .then(res => {
                const data = res.data.data
                
                self.graphicReport.series  = data.series
                self.graphicReport.options = {
                    chart: {
                        height: 350,
                        type: 'bar',
                        events: {
                            click: function(chart, w, e) {
                                // console.log(chart, w, e)
                            }
                        }
                    },
                    colors: ['#3498db', '#2ecc71', '#f1c40f', '#e74c3c', '#9b59b6', '#34495e', '#1abc9c', '#e67e22'],
                    plotOptions: {
                        bar: {
                        columnWidth: '45%',
                        distributed: true,
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    legend: {
                        show: false
                    },
                    xaxis: {
                        categories: data.categories,
                        labels: {
                            style: {
                                colors: ['#3498db', '#2ecc71', '#f1c40f', '#e74c3c', '#9b59b6', '#34495e', '#1abc9c', '#e67e22'],
                                fontSize: '12px'
                            }
                        }
                    }
                } 
            })
            .catch(err => {
                if (!err.response) {
                    // network error
                    err.response = {
                        data: {
                        message: 'Server error'
                        }
                    };
                }
                this.$vs.notify({
                title:'Opps something error',
                text: err.response.data.message,
                color:'danger'})
            })
            .finally(() => {
                this.onLoaded()
            })
        },
        getTicketByPriority () {
            const self = this
            this.$store.dispatch('dashboard/fetchTicketByPriority')
            .then(res => {
                const data = res.data.data

                self.ticketPriority = {
                    urgent: data.urgent,
                    major: data.major,
                    minor: data.minor,
                    question: data.question,
                    critical: data.critical,
                    emergency: data.emergency
                }
            })
        },
        getTicketByStatus () {
            const self = this
            this.$store.dispatch('dashboard/fetchTicketByStatus')
            .then(res => {
                const data = res.data.data

                self.ticketStatus = {
                    opened: data.opened,
                    inProcess: data.in_process,
                    inProcessPrincipal: data.in_process_principal,
                    inProcessCustomer: data.in_process_customer,
                    closed: data.closed
                }
            })
        },
        getStatusWarranty () {
            const self = this
            this.$store.dispatch('dashboard/fetchStatusWarranty')
            .then(res => {
                const data = res.data.data
                
                data.forEach(value => {
                    self.statusWarranty.push({
                        customer: value.customer.company_name,
                        level: value.level,
                        status: value.status,
                        po_reference: value.po,
                        do_reference: value.do_req,
                        periode: value.periode,
                        sales: value.sales
                    })
                });
            })
            .catch(err => {
                if (!err.response) {
                    // network error
                    err.response = {
                        data: {
                        message: 'Server error'
                        }
                    };
                }
                this.$vs.notify({
                title:'Opps something error',
                text: err.response.data.message,
                color:'danger'})
            })
            .finally(() => {
                this.onLoaded()
            })
        },
        getGraphicTicketProduct () {
            const self = this
            this.$store.dispatch('dashboard/fetchTicketGraphByProduct', { 
                filter: 'all'
            })
            .then(res => {
                const data = res.data.data
                
                self.graphicTicketProduct.series  = data.series
                self.graphicTicketProduct.options = {
                    chart: {
                        width: 350,
                        type: 'pie',
                    },
                    labels: data.labels,
                    legend: {
                        position: 'bottom',
                        horizontalAlign: 'left'
                    }
                } 
            })
            .catch(err => {
                if (!err.response) {
                    // network error
                    err.response = {
                        data: {
                        message: 'Server error'
                        }
                    };
                }
                this.$vs.notify({
                title:'Opps something error',
                text: err.response.data.message,
                color:'danger'})
            })
            .finally(() => {
                this.onLoaded()
            })
        },
        getGraphicTicketCompany () {
            const self = this
            this.$store.dispatch('dashboard/fetchTicketGraphByCompany', { 
                filter: 'all'
            })
            .then(res => {
                const data = res.data.data
                
                self.graphicTicketCompany.series  = data.series
                self.graphicTicketCompany.options = {
                    chart: {
                        width: 350,
                        type: 'pie',
                    },
                    labels: data.labels,
                    legend: {
                        position: 'bottom',
                        horizontalAlign: 'left'
                    }
                } 
            })
            .catch(err => {
                if (!err.response) {
                    // network error
                    err.response = {
                        data: {
                        message: 'Server error'
                        }
                    };
                }
                this.$vs.notify({
                title:'Opps something error',
                text: err.response.data.message,
                color:'danger'})
            })
            .finally(() => {
                this.onLoaded()
            })
        },
        onLoaded(){
            this.loaded = true;
            this.$vs.loading.close()
        },
        loading () {
            this.loaded = false;

            this.$vs.loading({
                type: 'border',
                color: 'primary',
                text: `Wait a minute, It's getting data.`
            })
        },
    },
    watch: {
        year () {
            this.getGraphicReport();
        },
        month () {
            this.getGraphicReport();
        },
    },
    created() {
        this.loading()
        this.getCurrentTime()
        this.getDeadlineTicket()
        this.getGraphicReport()
        this.getStatusWarranty()
        this.getTicketByStatus()
        this.getTicketByPriority()
        this.getGraphicTicketProduct()
        this.getGraphicTicketCompany()
    }
}
</script>
