export default {
    statusWarranty : [
        {
            customer: 'XL Axiata',
            level: 'Silver',
            status: 'Maintenance Kontrak',
            po_reference: '4500492724',
            do_reference: '-',
            periode: '01 April 2015 - 31 Maret 2016',
            sales: 'Rudi'
        },
        {
            customer: 'Indosat',
            level: 'Silver',
            status: 'Maintenance Kontrak',
            po_reference: '4800073411',
            do_reference: '-',
            periode: '01 Januari 2015 - 31 Desember 2015',
            sales: 'Aziz'
        },
        {
            customer: 'HCPT',
            level: 'Gold',
            status: 'Manage Service',
            po_reference: '200030093',
            do_reference: '-',
            periode: '1 April 2014 - 31 Maret 2015',
            sales: 'Romi'
        },
        {
            customer: 'Telkomsel',
            level: 'Gold',
            status: 'Maintenance Kontrak',
            po_reference: 'HOC120354',
            do_reference: '-',
            periode: '01 Januari 2015 - 31 Desember 2015',
            sales: 'Romi'
        },
    ],
    troubleTicket: [
        {
            number: '15130505211063',
            date: '05/05/2021',
            time: '11:05:45',
            deadline: '12 Mei 2021',
            product: 'Global Roamer',
            status: 'In Process',
            originator: 'Hilmi',
            email: 'hilmi@gmail.com',
            customer: 'HCPT',
            priority: 'Major',
            subject: 'Not Synchronize Timing Report on Global Roamer'
        },
        {
            number: '21151901211041',
            date: '19/01/2021',
            time: '11:05:45',
            deadline: '07 Jan 2021',
            product: 'Keynote Sigos',
            status: 'In Process',
            originator: 'Muhammad Faishal Akbar',
            email: 'faishal@argasolusi.com',
            customer: 'Telkomsel',
            priority: 'Major',
            subject: ' 	Maintenance wluPalembang Down'
        }
    ]
}